<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Event
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="eventCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <span class="step-class">STEP 1</span>
              Event Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Event Name*"
                label-for="h-event-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Event Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-name"
                    v-model="name"
                    placeholder="Event Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Booking Period*"
                label-for="h-event-bookingPeriod"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-bookingPeriod"
                    v-model="bookingPeriod"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, mode: 'range', dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @input="bookingPeriodValidation == true ? bookingPeriodValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="bookingPeriodValidation"
                  class="text-danger"
                >
                  {{ bookingPeriodError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Event Name (中文)*"
                label-for="h-event-nameSG"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Event Name (中文)"
                  vid="nameSG"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-nameSG"
                    v-model="nameSG"
                    placeholder="Event Name (中文)"
                    :state="(errors.length > 0 || nameSGValidation) ? false : null"
                    name="nameSG"
                    @input="nameSGValidation == true ? nameSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameSGValidation"
                    class="text-danger"
                  >
                    {{ nameSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-event-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-event-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Start Date*"
                label-for="h-event-startDate"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-startDate"
                    v-model="startDate"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: false, dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @input="startDateValidation == true ? startDateValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="startDateValidation"
                  class="text-danger"
                >
                  {{ startDateError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Allow Waiting List*"
                label-for="h-event-waitingList"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Allow Waiting List"
                  vid="waitingList"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-event-waitingList"
                    v-model="waitingList"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in waitingListOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <feather-icon
                        v-if="waitingList === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="waitingListValidation"
                    class="text-danger"
                  >
                    {{ waitingListInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="End Date*"
                label-for="h-event-endDate"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-endDate"
                    v-model="endDate"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: false, dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @input="endDateValidation == true ? endDateValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="endDateValidation"
                  class="text-danger"
                >
                  {{ endDateError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Timeslots Booking*"
                label-for="h-event-timeSlotBooking"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Timeslots Booking"
                  vid="timeSlotBooking"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-event-timeSlotBooking"
                    v-model="timeSlotBooking"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in timeSlotBookingOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <feather-icon
                        v-if="timeSlotBooking === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="timeSlotBookingValidation"
                    class="text-danger"
                  >
                    {{ timeSlotBookingInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Timeslots Introduction"
                label-for="h-event-timeSlotIntroduction"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Timeslots Introduction"
                  vid="timeSlotIntroduction"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-timeSlotIntroduction"
                    v-model="timeSlotIntroduction"
                    placeholder="Timeslots Introduction"
                    :state="(errors.length > 0 || timeSlotIntroductionValidation) ? false : null"
                    name="timeSlotIntroduction"
                    @input="timeSlotIntroductionValidation == true ? timeSlotIntroductionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="timeSlotIntroductionValidation"
                    class="text-danger"
                  >
                    {{ timeSlotIntroductionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="dividerHR">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Location*"
                label-for="h-event-location"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  vid="location"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-location"
                    v-model="location"
                    placeholder="Location"
                    :state="(errors.length > 0 || locationValidation) ? false : null"
                    name="location"
                    @input="locationValidation == true ? locationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationValidation"
                    class="text-danger"
                  >
                    {{ locationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Location (中文)*"
                label-for="h-event-locationSG"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location (中文)"
                  vid="locationSG"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-locationSG"
                    v-model="locationSG"
                    placeholder="Location (中文)"
                    :state="(errors.length > 0 || locationSGValidation) ? false : null"
                    name="locationSG"
                    @input="locationSGValidation == true ? locationSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationSGValidation"
                    class="text-danger"
                  >
                    {{ locationSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Date & Time Label*"
                label-for="h-event-dateLabel"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date & Time Label"
                  vid="dateLabel"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-dateLabel"
                    v-model="dateLabel"
                    placeholder="Date & Time Label"
                    :state="(errors.length > 0 || dateLabelValidation) ? false : null"
                    name="dateLabel"
                    @input="dateLabelValidation == true ? dateLabelValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateLabelValidation"
                    class="text-danger"
                  >
                    {{ dateLabelError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Date & Time Label (中文)*"
                label-for="h-event-dateLabelSG"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date & Time Label (中文)"
                  vid="dateLabelSG"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-dateLabelSG"
                    v-model="dateLabelSG"
                    placeholder="Date & Time Label (中文)"
                    :state="(errors.length > 0 || dateLabelSGValidation) ? false : null"
                    name="dateLabelSG"
                    @input="dateLabelSGValidation == true ? dateLabelSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateLabelSGValidation"
                    class="text-danger"
                  >
                    {{ dateLabelSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="12">
              <b-form-group
                label="Tags"
                label-for="h-event-tags"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tags"
                  vid="tags"
                  rules=""
                >
                  <b-form-tags
                    v-model="tags"
                    input-id="h-event-tags"
                    class=""
                    tag-variant="danger"
                    @input="tagsValidation == true ? tagsValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="tagsValidation"
                    class="text-danger"
                  >
                    {{ tagsError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="h-event-description"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="description"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-description"
                    v-model="description"
                    placeholder="Description"
                    :state="(errors.length > 0 || descriptionValidation) ? false : null"
                    name="description"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group
                label="Event Slug*"
                label-for="h-event-slug"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Event Slug"
                  vid="slug"
                  rules="required"
                >
                  <b-form-input
                    id="h-event-slug"
                    v-model="slug"
                    placeholder="Event Slug"
                    :state="(errors.length > 0 || slugValidation) ? false : null"
                    name="slug"
                    @input="slugValidation == true ? slugValidation = false : null; slugError = ''"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    class="text-danger"
                  >
                    {{ slugError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <span class="step-class">STEP 2</span>
              Feature Image
            </h3>
          </template>
          <b-col
            cols="12"
          >
            <vue-dropzone
              id="event-images"
              ref="myVueDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-files-added="processFile"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-error="handleError"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drop file here or click to upload
                </h3>
                <div class="subtitle">
                  The image should not bigger than 5 mb. JPEG or PNG only.
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mt-2"
                >
                  Upload file
                </b-button>
              </div>
            </vue-dropzone>
          </b-col>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <span class="step-class">STEP 3</span>
              Mass Create Bookable Timeslots
            </h3>
          </template>
          <b-row>
            <b-col cols="12">
              <h6
                class="mb-2"
              >
                The system can create timeslots in bulk during the creation of a new event. Simply define the following parameters.
              </h6>
            </b-col>
            <b-col cols="md-6">
              <b-form-group
                label="Start Date and Time"
                label-for="h-event-start"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-start"
                    v-model="start"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                    @input="startValidation == true ? startValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="startValidation"
                  class="text-danger"
                >
                  {{ startError }}
                </small>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="End Date and Time"
                label-for="h-event-end"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-end"
                    v-model="end"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                    @input="endValidation == true ? endValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="endValidation"
                  class="text-danger"
                >
                  {{ endError }}
                </small>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Operating Hours"
                label-for="h-availabilityScheme-operatingHours"
                label-cols-md="4"
              >
                <div class="inlineElement opening__hours">
                  <b-input-group>
                    <flat-pickr
                      v-model="operationStartTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="operationStartTimeValidation == true ? operationStartTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="operationStartTimeValidation"
                    class="text-danger"
                  >
                    {{ operationStartTimeError }}
                  </small>
                  <b-input-group>
                    <flat-pickr
                      v-model="operationEndTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="operationEndTimeValidation == true ? operationEndTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="operationEndTimeValidation"
                    class="text-danger"
                  >
                    {{ operationEndTimeError }}
                  </small>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Slots Duration (Min)"
                label-for="h-event-slotDuration"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Slots Duration (Min)"
                  vid="slotDuration"
                  rules="numeric"
                >
                  <b-form-input
                    id="h-event-slotDuration"
                    v-model="slotDuration"
                    placeholder="Slots Duration (Min)"
                    :state="(errors.length > 0 || slotDurationValidation) ? false : null"
                    name="slotDuration"
                    @input="slotDurationValidation == true ? slotDurationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="slotDurationValidation"
                    class="text-danger"
                  >
                    {{ slotDurationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Quantity Per Slot"
                label-for="h-event-quantityPerSlot"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Quantity Per Slot"
                  vid="quantityPerSlot"
                  rules="numeric|min_value:1"
                >
                  <b-form-input
                    id="h-event-quantityPerSlot"
                    v-model="quantityPerSlot"
                    placeholder="Quantity Per Slot"
                    :state="(errors.length > 0 || quantityPerSlotValidation) ? false : null"
                    name="quantityPerSlot"
                    @input="quantityPerSlotValidation == true ? quantityPerSlotValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="quantityPerSlotValidation"
                    class="text-danger"
                  >
                    {{ quantityPerSlotError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-events-index', params: { type: 'all-event' } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Event</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BFormTags, BInputGroupAppend, BFormTextarea, BFormRadioGroup, BFormRadio, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormTags,
    BFormRadio,
    BForm,
    BButton,
    BNav,

    vSelect,
    flatPickr,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      nameSG: '',
      startDate: '',
      endDate: '',
      bookingPeriod: '',
      status: 'published',
      waitingList: 'yes',
      timeSlotBooking: 'required',
      timeSlotIntroduction: '',
      dateLabel: '',
      dateLabelSG: '',
      location: '',
      locationSG: '',
      description: '',
      tags: [],
      slug: '',
      start: '',
      end: '',
      operationStartTime: '',
      operationEndTime: '',
      slotDuration: '',
      quantityPerSlot: '',
      images: [],
      typeIDError: 'Valid type ID is required',
      typeIDValidation: false,
      nameError: 'Valid name is required',
      nameValidation: false,
      nameSGError: 'Valid name in SG is required',
      nameSGValidation: false,
      startDateError: 'Valid start date is required',
      startDateValidation: false,
      endDateError: 'Valid end date is required',
      endDateValidation: false,
      bookingPeriodError: 'Valid booking period is required',
      bookingPeriodValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      waitingListError: 'Valid value is required',
      waitingListValidation: false,
      timeSlotBookingError: 'Valid value is required',
      timeSlotBookingValidation: false,
      timeSlotIntroductionValidation: false,
      timeSlotIntroductionError: 'Valid introduction is required',
      dateLabelValidation: false,
      dateLabelError: 'Valid label is required',
      dateLabelSGValidation: false,
      dateLabelSGError: 'Valid label in SG is required',
      locationValidation: false,
      locationError: 'Valid location is required',
      locationSGValidation: false,
      locationSGError: 'Valid location in SG is required',
      descriptionValidation: false,
      descriptionError: 'Valid description is required',
      tagsValidation: false,
      tagsError: 'Valid tags are required',
      slugValidation: false,
      slugError: '',
      startValidation: false,
      startError: 'Valid start date and time is required',
      endValidation: false,
      endError: 'Valid end date and time is required',
      slotDurationValidation: false,
      slotDurationError: 'Valid slot duration is required',
      quantityPerSlotValidation: false,
      quantityPerSlotError: 'Valid quantity per slot is required',
      operationStartTimeValidation: false,
      operationStartTimeError: 'Valid time is required',
      operationEndTimeValidation: false,
      operationEndTimeError: 'Valid time is required',
      waitingListOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      statusOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Draft', code: 'draft' },
      ],
      timeSlotBookingOptions: [
        { text: 'Required', value: 'required' },
        { text: 'Optional', value: 'optional' },
        { text: 'N/A', value: 'not available' },
      ],

      // dropzone
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png',
        maxFilesize: 5,
        addRemoveLinks: true,
        maxFiles: 1,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },

      // validation rules
      required,
    }
  },
  created() {
    if (this.$route.query.clone) {
      this.$http.get(`appointment/events/${this.$route.query.clone}`)
        .then(response => {
          this.name = response.data.name || ''
          this.nameSG = response.data.nameSG || ''
          this.location = response.data.location || ''
          this.locationSG = response.data.locationSG || ''
          this.startDate = response.data.startDate || ''
          this.endDate = response.data.endDate || ''
          this.dateLabel = response.data.dateLabel || ''
          this.dateLabelSG = response.data.dateLabelSG || ''
          this.bookingPeriod = response.data.bookingPeriod || ''
          this.waitingList = response.data.waitingList === true ? 'yes' : 'no'
          this.timeSlotBooking = response.data.timeSlotBooking || 'required'
          this.timeSlotIntroduction = response.data.timeSlotIntroduction || ''
          this.description = response.data.description || ''
          this.slug = response.data.slug || ''
          this.status = response.data.status || 'published'
          this.tags = response.data.tags || []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  methods: {
    processFile(file) {
      if (this.images.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can upload only one file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        const acceptedTypes = ['image/jpeg', 'image/png']
        file.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Image is too large',
              //     icon: 'AlertTriangleIcon',
              //     variant: 'danger',
              //   },
              // })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                this.images.push(event.target.result)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },

    fileRemoved() {
      this.images = []
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    submitForm() {
      this.$refs.eventCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('nameSG', this.nameSG)
          formData.append('startDate', this.startDate)
          formData.append('endDate', this.endDate)
          formData.append('dateLabel', this.dateLabel)
          formData.append('dateLabelSG', this.dateLabelSG)
          formData.append('location', this.location)
          formData.append('locationSG', this.locationSG)
          formData.append('bookingPeriod', this.bookingPeriod)
          formData.append('waitingList', this.waitingList)
          formData.append('timeSlotBooking', this.timeSlotBooking)
          formData.append('timeSlotIntroduction', this.timeSlotIntroduction)
          formData.append('start', this.start)
          formData.append('end', this.end)
          formData.append('operationStartTime', this.operationStartTime)
          formData.append('operationEndTime', this.operationEndTime)
          formData.append('slotDuration', this.slotDuration)
          formData.append('quantityPerSlot', this.quantityPerSlot)
          formData.append('description', this.description)
          formData.append('slug', this.slug)
          formData.append('status', this.status)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.tags.length; i++) {
            formData.append('tags[]', this.tags[i])
          }

          if (this.images.length) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.images.length; i++) {
              formData.append('images[]', this.images[i])
            }
          } else {
            formData.append('images', '')
          }

          this.$http.post('appointment/events/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Event Created',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Created Entry',
                  allowOutsideClick: false,
                  confirmButtonText: 'Back to Events',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-events-index', params: { type: 'all-event' } })
                    } else {
                      this.$router.push({ name: 'appointment-events-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'nameSG') {
                    this.nameSGError = validationError.msg
                    this.nameSGValidation = true
                  } else if (validationError.param === 'startDate') {
                    this.startDateError = validationError.msg
                    this.startDateValidation = true
                  } else if (validationError.param === 'endDate') {
                    this.endDateError = validationError.msg
                    this.endDateValidation = true
                  } else if (validationError.param === 'dateLabel') {
                    this.dateLabelError = validationError.msg
                    this.dateLabelValidation = true
                  } else if (validationError.param === 'dateLabelSG') {
                    this.dateLabelSGError = validationError.msg
                    this.dateLabelSGValidation = true
                  } else if (validationError.param === 'location') {
                    this.locationError = validationError.msg
                    this.locationValidation = true
                  } else if (validationError.param === 'locationSG') {
                    this.locationSGError = validationError.msg
                    this.locationSGValidation = true
                  } else if (validationError.param === 'bookingPeriod') {
                    this.bookingPeriodError = validationError.msg
                    this.bookingPeriodValidation = true
                  } else if (validationError.param === 'waitingList') {
                    this.waitingListError = validationError.msg
                    this.waitingListValidation = true
                  } else if (validationError.param === 'timeSlotBooking') {
                    this.timeSlotBookingError = validationError.msg
                    this.timeSlotBookingValidation = true
                  } else if (validationError.param === 'timeSlotIntroduction') {
                    this.timeSlotIntroductionError = validationError.msg
                    this.timeSlotIntroductionValidation = true
                  } else if (validationError.param === 'start') {
                    this.startError = validationError.msg
                    this.startValidation = true
                  } else if (validationError.param === 'end') {
                    this.endError = validationError.msg
                    this.endValidation = true
                  } else if (validationError.param === 'slotDuration') {
                    this.slotDurationError = validationError.msg
                    this.slotDurationValidation = true
                  } else if (validationError.param === 'quantityPerSlot') {
                    this.quantityPerSlotError = validationError.msg
                    this.quantityPerSlotValidation = true
                  } else if (validationError.param === 'slug') {
                    this.slugError = validationError.msg
                    this.slugValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'operationStartTime') {
                    this.operationStartTimeError = validationError.msg
                    this.operationStartTimeValidation = true
                  } else if (validationError.param === 'operationEndTime') {
                    this.operationEndTimeError = validationError.msg
                    this.operationEndTimeValidation = true
                  }
                })

                // const items = document.querySelectorAll('small.text-danger')
                // const elements = Array.from(items)
                // const element = elements.find(ele => ele.innerHTML)
                // if (element) {
                //   element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                // }

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
</style>
